
import { Dispatch } from "react";
import { Action } from "@reduxjs/toolkit";
import surveySlice, { surveyActions } from "./survey-slice";
import { IAnswer, ISubmitAnswersModel } from "../../components/survey/survey-questions/answers/answer";
import { IHealthAssesment } from "../../components/survey/survey-start/survey-start";
import { addBackendNotification, addFrontendNotification, addNetworkError } from "../notifications/notification-slice";
import { IQuestion } from "../../components/survey/survey-questions/survey-questions";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { ApiResponse } from "../../constants/constants";
import { NotificationHelper, NotificationType } from "../../Helpers/notification-helper";


const controller = 'Survey'

export const fetchQuestionGroups = (fetchExecute: any) => {

    return async (dispatch: Dispatch<Action>) => {
        // dispatch()

        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/${controller}/Query/GetQiestionGroups`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            })
            const apiResponse:ApiResponse = await response.json()
            if(apiResponse.IsSuccess)
            {
                dispatch(surveyActions.setQuestionGroups(apiResponse.Data));
                return apiResponse.Data
            }
            else
            {
                dispatch(addBackendNotification(apiResponse.Data))
            }
            // console.log(data)

            
        } catch (error) {
            dispatch(addNetworkError(""))
        }
    }
};


export const fetchQuestions = (fetchExecute: any) => {
    return (async (dispatch: Dispatch<Action>) => {
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/${controller}/Query/GetQuestions`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            })
            const apiResponse:ApiResponse = await response.json()
            if(apiResponse.IsSuccess)
            {
                dispatch(surveyActions.setQuestions(apiResponse.Data));
                return apiResponse.Data
            }
            else
            {
                dispatch(addBackendNotification(apiResponse.Data))
            }
        } catch (error) {
            dispatch(addNetworkError(""))
        }
    });
}

export const fetchAnswers = (fetchExecute: any, userId: number = 0, healthAssesmentId: number = 0) => {
    return (async (dispatch: Dispatch<Action>) => {
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/${controller}/Query/GetAnswers?UserId=${userId}&HealthAssesmentId=${healthAssesmentId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            })
            // console.log(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Query/GetAnswers?userId${userId}&healthAssesmentId=${healthAssesmentId}`)
            const apiResponse:ApiResponse = await response.json()
            if(apiResponse.IsSuccess)
            {
                const result: IAnswer[] = apiResponse.Data
                const x: IAnswer[] = result.map((answer: IAnswer) => {
                    const z: IAnswer = { ...answer, isSavedInDatabase: true };
                    return z;
                })
    
                dispatch(surveyActions.setAnswers(x))
            }
            else
            {
                dispatch(addBackendNotification(apiResponse.Data))
            }
            
        } catch (error) {
            dispatch(addNetworkError(""))
        }
    });



}

export const CreateaHealthAssesment = (fetchExecute: any, userId: number) => {
    return (async (dispatch: Dispatch<Action>) => {
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/${controller}/Command/CreateNewHealthAssesment/${userId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });

            const apiResponse: ApiResponse = await response.json();
            if (apiResponse.IsSuccess) {
                const HealthAssesment: IHealthAssesment = apiResponse.Data;
                dispatch(surveyActions.setHealthAssesment(HealthAssesment))
                dispatch(addFrontendNotification(NotificationHelper.getDefaultNotification(
                    NotificationType.INFO,
                    `Health assessment ID: ${HealthAssesment.id}`
                )))
            }
            else {
                dispatch(addBackendNotification(apiResponse.Data))
            }

        } catch (error) {
            dispatch(addNetworkError("Failed to create new health assessment.")) 
        }
    });
}

export const GetHealthAssesment = (fetchExecute: any, userId: number, healthAssesmentId: number = 0) => {
    return (async (dispatch: Dispatch<Action>) => {

        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/${controller}/Query/GetHealthAssesment?userId=${userId}&healthAssesmentId=${healthAssesmentId}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
            const apiResponse:ApiResponse = await response.json()
            if(apiResponse.IsSuccess)
            {
                const healthAssesment: IHealthAssesment[] = apiResponse.Data;
                if (healthAssesment.length > 0) {
                    dispatch(surveyActions.setHealthAssesment(healthAssesment[0]))
                    dispatch(surveyActions.setAllHealthAssesments(healthAssesment))
                }
            }
            else
            {
                dispatch(addBackendNotification(apiResponse.Data))
            }
            // console.log(data)
           

        } catch (error) {
            dispatch(addNetworkError(""))
        }
    });
}

export const SubmitAnswersInDatabase = (fetchExecute: any, answers: ISubmitAnswersModel) => {
    // const question: IQuestion[] = useSelector((state:RootState)=> state.survey.Questions)
    // .filter(q => q.id = answers.questionId) 

    return (async (dispatch: Dispatch<Action>) => {
        try {
            // console.log('response start', answers)
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/${controller}/Command/SubmitAnswers`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(
                    answers
                )
            });
            const apiResponse:ApiResponse = await response.json()
            if(apiResponse.IsSuccess)
            {
                dispatch(surveySlice.actions.setQuestion({ questionId: answers.questionId, isSavedInDB: true }))
            }
            else
            {
                dispatch(surveySlice.actions.setQuestion({ questionId: answers.questionId, isSavedInDB: false }))
                dispatch(addBackendNotification(apiResponse.Data))
            }
            
            // dispatch(surveySlice.actions.setQuestionError(
            //     { questionId: answers.questionId, errorMsg: undefined }))
        } catch (error: any) {
            dispatch(surveySlice.actions.setQuestion({ questionId: answers.questionId, isSavedInDB: false }))
            
            dispatch(addNetworkError(`Question ${answers.questionNumber}. is not saved in DB.`))
        }
    });


}

export const CompleteSurvey = (fetchExecute: any, haid: number) => {
    // console.log('haid', haid)
    return (async (dispatch: Dispatch<Action>) => {
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/${controller}/Command/CompleteSurvey/${haid}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            })
            const apiResponse: ApiResponse = await response.json();

            if (apiResponse.IsSuccess) {
                dispatch(addFrontendNotification(NotificationHelper.getDefaultNotification(
                    NotificationType.SUCCESS,
                        `Čestitamo! Uspešno ste popunili naš zdravstveni upitnik. 
                        Vaši odgovori se obrađuju. Nakon završene obrade, 
                        na našem portalu dobićete preporuku za Sistematski Pregled, 
                        personalizovanu za Vas. O završetku obrade obavestićemo Vas putem mail adrese koju ste uneli prilikom registracije.`,
                    true
                )))
            }
            else {
                dispatch(addBackendNotification(apiResponse.Data))
            }
        } catch (error) {
            dispatch(addNetworkError("Failure to complete survey"))
        }
    });
}

export const GetHealthExaminations = (fetchExecute: any) => {
    return (async (dispatch: Dispatch<Action>) => {
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/${controller}/Query/GetHealthExaminations`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });

            const apiResponse: ApiResponse = await response.json();
            if(apiResponse.IsSuccess)
            {
                dispatch(surveySlice.actions.setHealthExamination(apiResponse.Data))
            }
            else
            {
                dispatch(addBackendNotification(apiResponse.Data))
            }
            // console.log(data)
            
        } catch (error) {
            dispatch(addNetworkError(""))
        }
    });
}
