import React, { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { INotification } from "../../../store/notifications/notification-slice";

interface LinearProgressBarProps {
  notification: INotification,
  persist: boolean,
}

export const setColor = (severity: string): string => {
  switch (severity) {
    case "success":
      return "#4caf50"; // Green
    case "error":
      return "#f44336"; // Red
    case "warning":
      return "#ff9800"; // Orange
    case "info":
      return "#2196f3"; // Blue
    default:
      return "#2196f3"; // Default to blue
  }
};

export const setLightColor = (severity: string): string => {
  switch (severity) {
    case "success":
      return "#edf7ed"; // MUI Alert Success background
    case "error":
      return "#fce8e6"; // MUI Alert Error background
    case "warning":
      return "#fff4e5"; // MUI Alert Warning background
    case "info":
      return "#e8f4fd"; // MUI Alert Info background
    default:
      return "#e8f4fd"; // Default to MUI Info background
  }
};



const LinearProgressBar: React.FC<LinearProgressBarProps> = ({ notification, persist }) => {

  if(persist) return null;

  const [progress, setProgress] = useState(0); // Start from 0 to increase

  

  useEffect(() => {
    // Increment progress over the given duration
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval); // Stop the interval when progress reaches 100
          return 100;
        }
        // Increase progress fractionally based on the duration
        return prev + (100 / (10000 / 200)); // 100ms interval
      });
    }, 200); // Update every 100ms

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [10000]);

  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      sx={{
        position: "absolute",
        ml: 0.1,
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "transparent",
        "& .MuiLinearProgress-bar": {
          backgroundColor: setColor(notification.Color), 
        },
      }}
    />
  );
};

export default LinearProgressBar;
