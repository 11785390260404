
// import { useState } from 'react';
import logo from '../../MMC-Logo-40.png';
// import {Container, Nav, Navbar, NavLink } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { LoginModal } from './login/login-modal';
import { CustomModal } from '../modal/modal';

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../store/login-modal-form/login-modal-form';
import { RootState } from '../../store/store';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate  } from 'react-router-dom';

import { ApiResponse, Colors } from '../../constants/constants';
import surveySlice from '../../store/survey/survey-slice';
import { addFrontendNotification, addNetworkError } from '../../store/notifications/notification-slice';
import { NotificationHelper, NotificationType } from '../../Helpers/notification-helper';

function NavbarMain() {
  const UserData = useSelector((state: RootState) => state.loginModal);
  const HealthAssesment = useSelector((state: RootState) => state.survey.HealthAssesment);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  const settings = [
    UserData.userData.email || '',
    'Primer Izvestaja',
    'Logout',
    'HealthAssiementId'
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  const navigate = useNavigate(); 
  
  const handleLogout = async () => {
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Command/LogoutUser`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });

      const apiResponse:ApiResponse = await response.json()
      if (apiResponse.IsSuccess) {
        dispatch(setUserData({ IsAuthenticated: false, userData: {} }));


        localStorage.removeItem('userData'); 
        sessionStorage.removeItem('userData'); 
        window.location.href = '/';

        navigate('/'); // <-- use React Router's `useNavigate`
        dispatch(addFrontendNotification(NotificationHelper.getDefaultNotification(
          NotificationType.INFO,
          `Have a nice day! 👋😊`
        )))
        
      } else {
        console.error('Failed to log out.');
      }
    } catch (error) {
      console.error('Error logging out:', error);
      dispatch(addNetworkError(""))
    }
  };

  // const handleDownloadFile = () => {
  //   const link = document.createElement('a');
  //   link.download = 'RezultatiTestiranja.pdf';
  //   link.href = 'files/RezultatiTestiranja.pdf';
  //   link.click();
  // };

  const handleDropMenu = (setting: string) => {
    if (setting === 'Logout') {
      return (
        <Box onClick={handleLogout}>
          <LogoutIcon sx={{ height: '18px' }} />
          {setting}
        </Box>
      );
    }

    if (setting === 'HealthAssiementId') {
      return (
        <Box onClick={handleLogout}>
          {setting}: {HealthAssesment.id}
        </Box>
      );
    }
    if (setting === 'Primer Izvestaja') {
      return (
        <Box>
          <Link
            to="/MedicalCheckup"
            style={{ textDecoration: 'none', color: 'inherit' }} // Removes underline and uses parent color
          >
            <Box >
              {setting}
            </Box>
          </Link>
        </Box>
      );
    }

    return <Box>{setting}</Box>;
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: Colors.MMYellow1,
        color: 'black',
        border: '0.5px solid white',
        mb: 1,
        // display: 'fixed',
        width: 'auto',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Logo for larger screens */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
            onClick={() => dispatch(surveySlice.actions.setCurrentPage(0))}>
            <Link to="/" >
              <img src={logo} height="35" alt="MajaMayo logo" />
            </Link>
          </Box>
          {/* Logo for smaller screens */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
            onClick={() => dispatch(surveySlice.actions.setCurrentPage(0))}>
            <Link to="/">
              <img src={logo} height="35" alt="MajaMayo logo" />
            </Link>
          </Box>

          {/* Navbar Toggle Button for mobile */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="open menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            />
          </Box>

          {/* User Menu */}
          <Box sx={{ flexGrow: 0, width: '100%', display: 'flex', justifyContent: 'end' }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  sx={{ border: '1px solid' }}
                  alt={UserData.IsAuthenticated ? UserData.userData.email : 'user'}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography
                    textAlign="center"
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}
                  >
                    {handleDropMenu(setting)}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <CustomModal body={<LoginModal />} />
    </AppBar>
  );
}

export default NavbarMain;
