import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { App } from '../src/App';
import './styles/NewBootstrap.css';
// import 'survey-core/defaultV2.css';
import { BrowserRouter } from 'react-router-dom';
// import { NavbarMain } from './components/navbar/navbar';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { SnackbarProvider } from 'notistack'
import CustomSnackbarWrapper from './components/global/custom-snackbar/custom-snackbar-wrapper';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>

    <Provider store={store}>
      <SnackbarProvider Components={{
        default: CustomSnackbarWrapper
      }}>
        <App />
      </SnackbarProvider>
    </Provider>

  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
