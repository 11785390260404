import { Dispatch } from "react";
import { Action } from "@reduxjs/toolkit";
import { medicalCheckupActions } from "./checkup-slice";
import { ApiResponse } from "../../constants/constants";
import { addBackendNotification, addNetworkError } from "../notifications/notification-slice";

export const fetchHealthAssessmentScores = (fetchExecute: any, healthAssesmentId:number) => {
    return(async (dispatch: Dispatch<Action>) => {
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Query/GetHealthAssessmentScores/${healthAssesmentId}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });
            const apiResponse: ApiResponse = await response.json()
            if(apiResponse.IsSuccess)
            {
                dispatch(medicalCheckupActions.setHealthAssessmentScores(apiResponse.Data))
            }
            else
            {
                dispatch(addBackendNotification(apiResponse.Data))
            }
            
            // console.log(data)
        } catch (error) {
            dispatch(addNetworkError("Failed to get health assessment scores."))
        }
    });
}

// export const handleDGRequests = (fetchExecute: any, healthAssesmentId:number) => {

// }