import React, { useEffect, useRef, useState } from 'react';
import { Box, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { IUser } from '../../components/survey/survey-form/survey-form';
import { Colors, Sizes } from '../../constants/constants';
import BarChartComponent, { IHealthAssesmentScores } from '../../components/medical-checkup/barchart/medical-checkup-barchart';
import ReportDataGrid, { IHealthExamination } from '../../components/medical-checkup/report/report';
// import ReportTable from '../../components/medical-checkup/report/report';
import { IAnswer } from '../../components/survey/survey-questions/answers/answer';
import ReportTable from '../../components/medical-checkup/report/report';
import { GetHealthExaminations } from '../../store/survey/survey-actions';
import Image from 'mui-image'
import slika from 'C:/Users/CHNEKNE/MajaMayo.Frontend/src/assets/question-groups/v870-tang-36.jpg'
import { fetchHealthAssessmentScores } from '../../store/medical-checkup-page/checkup-actions';
import { NewSurvey } from '../../components/medical-checkup/new-survey/new-survey';
import { PictureAsPdf } from '@mui/icons-material';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";


export interface IMedicalCheckupProp {
    checkupType: 'Opsti' | 'KV' | 'Probava';
}

export const MedicalCheckupPage: React.FunctionComponent<IMedicalCheckupProp> = ({ checkupType }) => {
    const userData: IUser = useSelector((state: RootState) => state.loginModal.userData);

    const answers: IAnswer[] = useSelector((state: RootState) => state.survey.Answers)
    const dispatch: any = useDispatch();

    const healthAssessmentScores: IHealthAssesmentScores[] = useSelector((state: RootState) => state.medicalCheckup.healthAssessmentScores);
    const healthAssessment = useSelector((state: RootState) => state.survey.HealthAssesment);
    // console.log(healthAssessmentScores)
    const pdfRef = useRef<HTMLDivElement>(null);

    useEffect(() => { dispatch(fetchHealthAssessmentScores(fetch, healthAssessment.id ?? 0)); }, [])

    const reportTable: IHealthExamination[] = useSelector((state: RootState) => state.survey.HealthExaminations)
    useEffect(() => {
        dispatch(GetHealthExaminations(fetch))
    }, [])




    const downloadPDF = () => {
        if (pdfRef.current) {
            const { width, height } = pdfRef.current.getBoundingClientRect();
            const doc = new jsPDF({
                orientation: "p", 
                unit: "mm", 
                format: [width, height], 
            });


            html2canvas(pdfRef.current, { useCORS: true }).then((canvas) => {

                const imgData = canvas.toDataURL("image/png");
                doc.addImage(imgData, "PNG", 1, 1, width, height); 

                doc.save("single-page.pdf");
            });
        }
    };



    // useEffect(() => { setReportTable(reportData()) }, [answers])
    const browserWidth = useSelector((state: RootState) => state.general.browserWidth)
    let boxWidth = {}
    if (browserWidth != undefined && browserWidth > Sizes.BreakPoint) boxWidth = { width: '75%' }
    if (browserWidth != undefined && browserWidth < Sizes.BreakPoint) boxWidth = { width: '100%' }

    return (
        <Box sx={{ ...boxWidth, my: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => downloadPDF()}
                    sx={{
                        backgroundColor: Colors.DarkerGreen,
                        color: 'white',
                        my: 1,
                        fontWeight: 'bold',
                        textTransform: 'none',
                        padding: '8px 16px',
                        borderRadius: '8px',
                        '&:hover': {
                            backgroundColor: Colors.DarkGreen,
                        },
                    }}
                    startIcon={<PictureAsPdf />}
                >
                    Download PDF
                </Button></Box>

            <Box p={3} flexDirection="column" alignItems="center" sx={{ width: 'auto', p: 0 }} ref={pdfRef}>
                <Paper elevation={3} style={{ padding: '0px', maxWidth: '100%', textAlign: 'center', backgroundColor: Colors.MMYellow2lighter }}>
                    <Grid container spacing={0} sx={{ mb: 0 }}>
                        <Grid item xs={12} >
                            {/* <Typography variant="h4">IZVESTAJ</Typography> */}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        {/* Row for Korisničke informacije and BarChartComponent */}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {/* Korisničke informacije */}
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ backgroundColor: Colors.DarkGreen, p: 2, height: '100%' }}>
                                        <Typography variant="h6" color="white" gutterBottom>
                                            Korisničke informacije
                                        </Typography>
                                        <TableContainer >
                                            <Table size="small" sx={{ borderCollapse: 'collapse' }}>
                                                <TableBody >
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Ime</TableCell>
                                                        <TableCell sx={{ color: 'white' }}>{userData.firstName} {userData.lastName}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Email</TableCell>
                                                        <TableCell sx={{ color: 'white' }}>{userData.email}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Pol</TableCell>
                                                        <TableCell sx={{ color: 'white' }}>{userData.gender}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Datum Rođenja</TableCell>
                                                        <TableCell sx={{ color: 'white' }}>{userData.dateOfBirth
                                                            ? new Date(userData.dateOfBirth).toLocaleDateString()
                                                            : 'N/A'}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Tezina (kg)</TableCell>
                                                        <TableCell sx={{ color: 'white' }}>{userData.weight}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Visina (cm)</TableCell>
                                                        <TableCell sx={{ color: 'white' }}>{userData.height}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Upitnik ID</TableCell>
                                                        <TableCell sx={{ color: 'white' }}>{healthAssessment.id}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Upitnik zavrsen</TableCell>
                                                        <TableCell sx={{ color: 'white' }}>{healthAssessment.completedOn
                                                            ? new Date(healthAssessment.completedOn).toLocaleDateString()
                                                            : 'N/A'}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Grid>

                                {/* BarChartComponent */}
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ maxHeight: '500px', overflowY: 'auto', p: 2 }}>
                                        <BarChartComponent />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Row for ReportTable */}
                        <Grid item xs={12}>
                            {/* <Paper elevation={0} sx={{ p: 2, mt: 2 }}> */}
                            <ReportTable data={healthAssessmentScores.length
                                ? [healthAssessmentScores.reduce((max, item) => (item.score > max.score ? item : max), healthAssessmentScores[0])]
                                : []} />
                            {/* <ReportTable data={healthAssessmentScores} /> */}
                            {/* </Paper> */}
                        </Grid>
                    </Grid>




                    {/* Report Section */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/* <Typography variant="h6" gutterBottom>
                                Sistematski pregledi
                            </Typography> */}
                            {/* <ReportTable data={healthAssessmentScores} /> */}
                            <NewSurvey />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Box>


    );
};
