import React from 'react';


interface CustomSnackbarProps {
  content?: React.ReactNode; 
}

const CustomSnackbarWrapper: React.FC<CustomSnackbarProps> = ({
  content,
}) => {
  return (
    <>{content}</>
  );
};

export default CustomSnackbarWrapper;
