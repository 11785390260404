
import { Dispatch } from 'react'
import { Action } from 'redux' 
import {  IModal, setUserData } from './login-modal-form';
import { IUser } from '../../components/survey/survey-form/survey-form';
import { dispatch } from 'd3';
import { CredentialResponse } from '@react-oauth/google';
import { ApiResponse } from '../../constants/constants';
import { addBackendNotification, addFrontendNotification, addNetworkError } from '../notifications/notification-slice';
import { NotificationHelper, NotificationType } from '../../Helpers/notification-helper';

// import { Action } from "@reduxjs/toolkit";


export const RegisterUser = (fetchExecute: any, email: string, pwd: string) => {
    return(async (dispatch: Dispatch<Action>)=>{
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Command/RegisterUser`,{
                method: 'POST',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    Email: email,
                    Pwd: pwd
                })
            })
            const apiResponse: ApiResponse= await response.json();
            
            if(apiResponse.IsSuccess)
            {
                dispatch(addFrontendNotification(NotificationHelper.getDefaultNotification(
                    NotificationType.SUCCESS,
                    "Your registration was successfull. Try to login."
                )))
            }
            else
            {
                dispatch(addBackendNotification(apiResponse.Data))
                console.log(apiResponse.Data)
            }
        } catch (error) {
            dispatch(addNetworkError(""));
        }
    });
}

export const LoginUser = (fetchExecute: any, email: string, pwd: string, loginAttempt: number) => {
    return(async (dispatch: Dispatch<Action>) => {
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Command/LoginUser`,
                {
                    method: 'POST',
                    headers: {'Content-Type':'application/json'},
                    body: JSON.stringify({
                        Email: email, Pwd: pwd
                    }),
                    credentials: 'include'
                }
                
            )
            
        
            const apiResponse:ApiResponse = await response.json();
            if(apiResponse.IsSuccess)
            {
                    const res: IModal = {IsAuthenticated: true, userData:apiResponse.Data }
                    dispatch(setUserData(res))


                    let msg = '';
                    if(res.userData.firstName != "" ){
                        msg = `Welcome back, ${res.userData.firstName}! 😊`
                    }
                    else{
                        msg = `Welcome! You are logged in succesfully! 😊`
                    }

                    dispatch(addFrontendNotification(NotificationHelper.getDefaultNotification(
                        NotificationType.SUCCESS,
                        msg
                    )));
                    dispatch(addFrontendNotification(NotificationHelper.getDefaultNotification(
                        NotificationType.WARNING,
                        `This account is not DELTA GENERALI approved.`
                    )));
            } else if(apiResponse.StatusCode === 429)
            {
                dispatch(addFrontendNotification(NotificationHelper.getDefaultNotification(
                    NotificationType.WARNING,
                    `You've reached the maximum number of login attempts. Please try again later.`
                )));
                dispatch(setUserData({IsAuthenticated:false, userData:{}}))
            }
            else{
                    dispatch(addBackendNotification(apiResponse.Data))
                    const res: IModal = {IsAuthenticated: false, userData:{} }
                    dispatch(setUserData(res))
                    // dispatch(addNetworkError(""));
            }
        } catch (error:any) {
            
            dispatch(setUserData({IsAuthenticated:false, userData:{}}))
            dispatch(addNetworkError(""));
        }
    });

    
}

export const CookieLoginUser = (fetchExecute: any) => {
    return(async(dispatch: Dispatch<Action>)=>{
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Command/CookieLoginUser`,{
                method: 'POST',
                headers: {'Content-Type':'application/json'},
                credentials: 'include'
            });
            const data:ApiResponse = await response.json();
            if(data.IsSuccess == true)
            {
                const res: IModal = {IsAuthenticated: true, userData: data.Data}
                dispatch(setUserData(res));

                let msg = '';
                    if(res.userData.firstName != "" ){
                        msg = `Welcome back, ${res.userData.firstName}! 😊`
                    }
                    else{
                        msg = `Welcome! You are logged in succesfully! 😊`
                    }
                dispatch(addFrontendNotification(NotificationHelper.getDefaultNotification(
                    NotificationType.SUCCESS,
                    msg
                )));
                dispatch(addFrontendNotification(NotificationHelper.getDefaultNotification(
                    NotificationType.WARNING,
                    `This account is not DELTA GENERALI approved.`
                )));
            }
            else
            {
                const res: IModal = {IsAuthenticated: false, userData: {}}
                dispatch(setUserData(res));
                // dispatch(addFrontendNotification(NotificationHelper.getDefaultNotification(
                //     NotificationType.INFO,
                //     `Welcome to the app! We\'re glad to have you here. 😊`
                // )));
            }
            
            
            // console.log("CookieLoginUser",data)
        } catch (error) {
            console.error("CookieLoginUser",error)
            dispatch(setUserData({IsAuthenticated: false, userData: {}}));
            dispatch(addNetworkError(""));
        }
    });
}

export const GoogleAuth = (fetchExecute: any, data: CredentialResponse) => {
    return (async (dispatch: Dispatch<Action>)=>{

        const x = {
            ClientId: data.clientId,
            Credential: data.credential
        }
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Command/GoogleLogin`,{
                method: 'POST',
                headers: {'Content-Type':'application/json'},
                credentials: 'include',
                body: JSON.stringify(x),
                
            })
            const data = await response.json();
            // console.log("data", data.Data)
            const res: IModal = {IsAuthenticated: true, userData: data.Data}
            // console.log("data", res)
            dispatch(setUserData(res));
        } catch (error) {
            // console.log(error)
            dispatch(setUserData({IsAuthenticated:false, userData:{}}))
            dispatch(addNetworkError(""));
        }
    });
}

export const UpdateUserData = (fetchExecute: any, userData: IUser, healthAssessmentId: number | null) => {
    const body = {
        // cardiovascular: userData.cardiovascular,
        // diabetes: userData.diabetes,
        // cancer: userData.cancer,
        // cancerType: userData.cancerType,
        // highBloodPressure: userData.highBloodPressure,
        // other: userData.other,
        // otherConditions: userData.otherConditions,
        // mentalIllness: userData.mentalIllness,
        id: userData.id, 
        firstName: userData.firstName,
        lastName: userData.lastName,
        dateOfBirth: userData.dateOfBirth,
        gender: userData.gender,
        // phoneNumber: userData.phoneNumber,
        email: userData.email,
        height: Number(userData.height),
        weight: Number(userData.weight),
        healthAssessmentId: healthAssessmentId

    };
    return (async (dispatch: Dispatch<Action>)=>{
        try {
            const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Command/UpdateUserData`,{
                method: 'PUT',
                headers: {'Content-Type':'application/json'},
                credentials: 'include',
                body: JSON.stringify(body)
            })
            const apiResponse:ApiResponse = await response.json();
            if(apiResponse.IsSuccess)
            {
                
            }
            else
            {
                dispatch(addBackendNotification(apiResponse.Data))
            }
            // console.log('UpdateUserData', data)
        } catch (error) {
            dispatch(addNetworkError(""));
        }
    });
}

// export const InsertUpdateFamilyHistory = (fetchExecute: any, userData: IUser) => {
//     const body = {
//         cardiovascular: userData.cardiovascular,
//         diabetes: userData.diabetes,
//         cancer: userData.cancer,
//         cancerType: userData.cancerType,
//         highBloodPressure: userData.highBloodPressure,
//         other: userData.other,
//         otherConditions: userData.otherConditions,
//         mentalIllness: userData.mentalIllness,
//         id: userData.id, 
//         // firstName: userData.firstName,
//         // lastName: userData.lastName,
//         // dateOfBirth: userData.dateOfBirth,
//         // gender: userData.gender,
//         // phoneNumber: userData.phoneNumber,
//         // email: userData.email
//     };
//     return(async (dispatch: Dispatch<Action>)=>{
//         try {
//             const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Command/InsertUpdateFamilyHistory`,{
//                 method: 'PUT',
//                 headers:{'Content-Type':'application/json'},
//                 credentials: 'include',
//                 body: JSON.stringify(userData)
//             })
//             const data = await response.json();
//             // console.log('InsertUpdateFamilyHistory', data)
//         } catch (error) {
//             console.error('InsertUpdateFamilyHistory', error)
//         }
//     });
// }

// export interface IFamilyHistory {
//     id: number;                       // PK, FK, not null
//     cardiovascular?: boolean | null;  // bit, null
//     diabetes?: boolean | null;        // bit, null
//     cancer?: boolean | null;          // bit, null
//     cancerType?: string | null;       // nvarchar(255), null
//     highBloodPressure?: boolean | null; // bit, null
//     other?: boolean | null;           // bit, null
//     otherConditions?: string | null;  // nvarchar(255), null
//     mentalIllnessInFamily?: boolean | null; 
// }



// export const GetFamilyHistory = (fetchExecute: any, id: number) => {
//     return(async(dispatch: Dispatch<Action>)=>{
//         try {
//             const response = await fetchExecute(`${process.env.REACT_APP_API_ENDPOINT}/Survey/Query/GetFamilyHistory/${id}`,
//                 {
//                     method: 'GET',
//                     headers:{'Content-Type':'application/json'},
//                     credentials: 'include',
//                 }
//             )

//             const data = await response.json();
//             const res: IFamilyHistory = data.Data
//             // console.log("rezultat", res)
//             dispatch(setFamilyHistory(res))
//         } catch (error) {
            
//         }
//     });
// }