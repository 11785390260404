import { AlertColor } from "@mui/material";
import { INotification } from "../store/notifications/notification-slice";
import { v4 as uuidv4 } from 'uuid';

export enum NotificationType {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}

export class NotificationHelper {

    

    public static colorSnackBar(x: string): AlertColor {
        switch (x.toLocaleLowerCase()) {
            case 'error':
                return NotificationType.ERROR;
            case 'success':
                return NotificationType.SUCCESS;
            case 'info':
                return NotificationType.INFO;
            case 'warning':
                return NotificationType.WARNING;
            default:
                return NotificationType.ERROR; 
        }
    }

    
    public static getDefaultNotification(logLevel: string = "", message: string = "", persist: boolean = false): INotification {
        return {
            Guid: uuidv4(),  
            LogLevel: logLevel,
            Message: message,
            Exception: '',
            EventId: null,
            Source: 'System',
            RequestPath: '/',
            UserId: 0,
            Origin: null,
            Page: null,
            IsActive: true,
            Color: NotificationHelper.colorSnackBar(logLevel), 
            Options: {
                variant: 'info',
                autoHideDuration: 3000,
                persist: persist,
                preventDuplicate: true
            }
        };
    }

   
}

// // Example usage of static methods
// const notification = NotificationUtils.getDefaultNotification('success', 'This is a success message');
// console.log(notification);
