import { Card, Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import NavbarMain from '../components/navbar/navbar';
import { Alert, Box, Button, LinearProgress, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import backgroundImage from '../assets/question-groups/v870-tang-36.jpg';
import { useEffect, useState } from 'react';
import { clearNotifications, INotification, removeNotification, selectAllNotifications } from '../store/notifications/notification-slice';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgressBar, { setColor, setLightColor } from '../components/global/progress-bar/linear-progress-bar';
import { EnqueueSnackbar, SnackbarContent, useSnackbar } from 'notistack';
import CustomSnackbarWrapper from '../components/global/custom-snackbar/custom-snackbar-wrapper';
// import { DefaultSnackbar } from '../components/global/custom-snackbar/default-snackbar';

interface ILayoutPage {
  children?: React.ReactNode;
}
export const LayoutPage: React.FunctionComponent<ILayoutPage> = props => {
  const browserWidth = useSelector((state: RootState) => state.general.browserWidth);
  let boxWidth = {};
  if (browserWidth !== undefined && browserWidth > 768) boxWidth = { width: '75%' };
  if (browserWidth !== undefined && browserWidth < 768) boxWidth = { width: '100%' };

  const notifications: INotification[] = useSelector((state: RootState) => state.notificationsGlobal);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return (
    <div
      style={{
        position: 'relative',
        minHeight: '100vh',
      }}
    >
      {/* Background Image with Blur Overlay */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          filter: 'blur(0px)',
          zIndex: -1,
        }}
      ></div>

      {/* Dark Overlay */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          zIndex: -1,
        }}
      ></div>

      <NavbarMain />
      <Container className="h-100" style={{ minHeight: '75%', backgroundColor: 'transparent' }}>
        <Card className="border-0 d-flex justify-content-center" style={{ backgroundColor: 'transparent' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Outlet />
          </Box>
        </Card>

        {notifications.length > 0 &&
          notifications.map((noti) => {
            <CustomSnackbarWrapper content={
              enqueueSnackbar(noti.Message, {
                variant: noti.Color,
                autoHideDuration: 10000,
                persist: noti.Options?.persist,
                content: (key) => (
                  <SnackbarContent
                    key={key}
                    style={{
                      width: browserWidth !== undefined && browserWidth < 768 ? 'auto' : '400px',
                      transform: `scale(${browserWidth !== undefined && browserWidth < 768 ? 0.85 : 1})`
                      // maxWidth: '500px',
                    }}

                  ><Alert severity={noti.Color} sx={{
                    width: "100%"
                    // ,transform: `scale(${browserWidth !== undefined && browserWidth < 768 ? 0.85 : 1})` 
                  }}
                    onClose={() => closeSnackbar(key)}>
                      {noti.Message}

                    </Alert>
                    {/* {!noti.Options?.persist && } */}
                    <LinearProgressBar notification={noti} persist={noti.Options.persist}
                    // scaleInfo={`scale(${browserWidth !== undefined && browserWidth < 768 ? 0.85 : 1})`}
                    /></SnackbarContent>
                ),
                onClose: () => dispatch(removeNotification(noti.Guid)), // ✅ Remove notification only when closed
              })
          }/>
           
            dispatch(removeNotification(noti.Guid))
            return true;
          })
        }

      </Container>

    </div>
  );
};




{/* <Snackbar open={notifications.length > 0} sx={{ width: "25%", backgroundColor: 'transparent' }} >
          <Box>
            {
              notifications.map((notif) => {
                return (
                  <Snackbar sx={{ width: "100%", backgroundColor: 'transparent', position: 'relative', mt: 1 }}
                    open={true}
                    autoHideDuration={10}
                    onClose={() => setTimeout(() => dispatch(removeNotification(notif.Guid)), 10000)}
                  >
                    <Alert onClose={() => dispatch(removeNotification(notif.Guid))} severity={notif.Color} sx={{ width: "100%" }}>
                      {notif?.Message}
                      <LinearProgressBar notification={notif} />
                    </Alert>
                  </Snackbar>
                );
              })}
          </Box>
        </Snackbar> */}