import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CustomStack from "../../Helpers/stack";
import { AlertColor, SnackbarOrigin } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import { NotificationHelper, NotificationType } from "../../Helpers/notification-helper";
// import { colorSnackBar, getDefaultNotification } from "../../Helpers/notification-helper";

interface OptionsObject {
    variant: AlertColor;
    autoHideDuration: number;
    anchorOrigin?: SnackbarOrigin;
    persist: boolean ;
    // action?: SnackbarAction;
    preventDuplicate?: boolean;

  }

export interface INotification {
    Guid: string;
    LogLevel: string;
    Message: string;
    Exception: string;
    EventId: number | null;
    Source: string;
    RequestPath: string;
    UserId: number;
    Origin: 'backend' | 'frontend' | null;
    Page: string | null;
    IsActive: true;
    Color: AlertColor  ;
    Options: OptionsObject
}

const initialState: INotification[] = [
    // {
    //   Guid: '1',
    //   LogLevel: 'info',
    //   Message: 'Welcome to the app! We\'re glad to have you here. 😊',
    //   Exception: '',
    //   EventId: null,
    //   Source: 'System',
    //   RequestPath: '/home',
    //   UserId: 0, // Assuming this is a guest user
    //   Origin: null,
    //   Page: 'Home',
    //   IsActive: true,
    //   Color: 'info',
    //   Options: {
    //     variant: 'info', // Matches the color
    //     autoHideDuration: 3000, // Snackbar will disappear after 3 seconds
    //     anchorOrigin: { vertical: 'top', horizontal: 'center' }, // Positioned at the top center
    //     persist: false, // Will not stay indefinitely
    //     preventDuplicate: true // Avoid showing multiple times
    //   }
    // }
  ];
  

const notificationSlice = createSlice({
    name: "notificationsGlobal",
    initialState,
    reducers: {
        addBackendNotification: (state, action: PayloadAction<INotification>) => {
            return [...state, { ...action.payload, Origin: 'backend', Color: NotificationHelper.colorSnackBar(action.payload.LogLevel), Guid: uuidv4(),
             Options: action.payload.Options ?? NotificationHelper.getDefaultNotification().Options
            }];
        },
        addFrontendNotification: (state, action: PayloadAction<INotification>) => {
            return [...state, { ...action.payload, Origin: 'frontend', Color: NotificationHelper.colorSnackBar(action.payload.LogLevel), Guid: uuidv4(),
                Options: action.payload.Options ?? NotificationHelper.getDefaultNotification().Options  }];
        },
        addNetworkError: (state, action: PayloadAction<string>) => {
            const additionalMsg = action.payload;
            return [...state, NotificationHelper.getDefaultNotification(
                NotificationType.ERROR,
                `Network error, check your connection. ${additionalMsg}`
            )]
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            const Guid = action.payload;
            return state.filter((e) => e.Guid !== Guid); 
        },
        clearNotifications: () => {
            return []; 
        }
    }
});

export const { addBackendNotification, addFrontendNotification, removeNotification, clearNotifications, addNetworkError } = notificationSlice.actions;
export default notificationSlice.reducer;

export const selectAllNotifications = (state: { notificationGlobal: INotification[] }) => state.notificationGlobal;








//test objects

// [
//     {
//       Guid: '1',
//       LogLevel: 'error',
//       Message: 'An error occurred while fetching data.',
//       Exception: 'TimeoutException',
//       EventId: 1001,
//       Source: 'API',
//       RequestPath: '/api/data',
//       UserId: 123,
//       Origin: 'backend',
//       Page: 'Dashboard',
//       IsActive: true,
//       Color: 'error' // color corresponds to LogLevel
//     },
//     {
//       Guid: '2',
//       LogLevel: 'success',
//       Message: 'Data successfully fetched.',
//       Exception: '',
//       EventId: 1002,
//       Source: 'API',
//       RequestPath: '/api/data',
//       UserId: 124,
//       Origin: 'frontend',
//       Page: 'Dashboard',
//       IsActive: true,
//       Color: 'success' // color corresponds to LogLevel
//     },
//     {
//       Guid: '3',
//       LogLevel: 'info',
//       Message: 'Server status: All systems are operational.',
//       Exception: '',
//       EventId: 1003,
//       Source: 'System',
//       RequestPath: '/api/status',
//       UserId: 125,
//       Origin: 'backend',
//       Page: 'Status',
//       IsActive: true,
//       Color: 'info' // color corresponds to LogLevel
//     },
//     {
//       Guid: '4',
//       LogLevel: 'warning',
//       Message: 'User attempted invalid login.',
//       Exception: 'InvalidCredentialsException',
//       EventId: 1004,
//       Source: 'Auth',
//       RequestPath: '/api/login',
//       UserId: 126,
//       Origin: 'frontend',
//       Page: 'Login',
//       IsActive: true,
//       Color: 'warning' // color corresponds to LogLevel
//     }
//   ];